/*------------------------------------------------------------------
[28. Misc : Helper Classes]
*/

.custom {
    height: 150px;
}

.icon-list {
    .fa-item {
        display: block;
        color: $color-master-darkest;
        line-height: 32px;
        height: 32px;
        padding-left: 10px;
        &>i {
            width: 32px;
            font-size: 14px;
            display: inline-block;
            text-align: right;
            margin-right: 10px;
        }
    }
}


/* Thumbnail for icons and profile pics
------------------------------------
*/

.thumbnail-wrapper {
    display: inline-block;
    overflow: hidden;
    float: left;
    &.circular {
        @include border-radius(50%);
    }
    &.bordered {
        border-width: 4px;
        border-style: solid;
        &.d16 {
            @include square(16+4*1px);
            &>* {
                line-height: 12px
            }
        }
        &.d24 {
            @include square(24+4*1px);
            &>* {
                line-height: 24px
            }
        }
        &.d32 {
            @include square(32+4*1px);
            &>* {
                line-height: 28px
            }
        }
        &.d48 {
            @include square(48+4*1px);
            &>* {
                line-height: 44px
            }
        }
    }
    &.d16 {
        @include square(16px);
        &>* {
            line-height: 16px
        }
    }
    &.d24 {
        @include square(24px);
        &>* {
            line-height: 24px
        }
    }
    &.d32 {
        @include square(32px);
        &>* {
            line-height: 32px
        }
    }
    &.d39 {
        @include square(39px);
        &>* {
            line-height: 39px
        }
    }
    &.d48 {
        @include square(48px);
        &>* {
            line-height: 50px
        }
    }
    &>* {
        vertical-align: middle;
        width: 100%;
        height: 100%;
        text-align: center;
    }
}


/* Profile dropdown
------------------------------------
*/

.profile-dropdown {
    background: #fff;
    padding: 0;
    &:before {
        position: absolute;
        top: -7px;
        right: 15px;
        display: inline-block;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #ccc;
        border-left: 7px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }
    &:after {
        position: absolute;
        top: -6px;
        right: 16px;
        display: inline-block;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #ffffff;
        border-left: 6px solid transparent;
        content: '';
    }
    &>a {
        opacity: .5;
        @include transition(opacity ease .3s);
        padding-left: 17px;
        padding-right: 37px;
        min-width: 138px;
        &>i {
            margin-right: 5px;
        }
        &:hover {
            opacity: 1;
        }
        &:last-child {
            margin-top: 11px;
            padding-bottom: 3px !important;
            padding-top: 3px;
        }
    }
}

.profile-dropdown-toggle {
    background: transparent;
    border: none;
}


/* Scroll
------------------------------------
*/

.scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}


/* Overides
------------------------------------
*/

.no-padding {
    padding: 0px !important;
}

.no-margin {
    margin: 0px !important;
}

.no-overflow {
    overflow: hidden !important;
}

.auto-overflow {
    overflow: auto;
}

.center-margin {
    margin-left: auto;
    margin-right: auto;
}

.inherit-size {
    width: inherit;
    height: inherit
}

.inherit-height {
    height: inherit;
}

.image-responsive-height {
    width: 100%;
}

.image-responsive-width {
    height: 100%;
}

.overlayer {
    position: absolute;
    display: block;
    z-index: 21;
    &.fullwidth {
        width: 100%;
    }
}

.overlayer-wrapper {
    position: relative;
    display: block;
    z-index: 10;
}

.overlay-fixed {
    position: fixed !important;
    top: auto !important;
}

.top-left {
    position: absolute !important;
    top: 0;
    left: 0;
}

.top-right {
    position: absolute !important;
    top: 1px;
    right: 0;
}

.bottom-left {
    position: absolute !important;
    bottom: 1px;
    left: 0;
}

.bottom-right {
    position: absolute !important;
    bottom: 0;
    right: 0;
}

.pull-bottom {
    position: absolute !important;
    bottom: 0;
}

.pull-up {
    position: absolute !important;
    top: 0;
}

.pull-center {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    display: table;
    z-index: 1;
}

.cursor {
    cursor: pointer;
}

.scroll-x-hidden {
    overflow-x: hidden !important;
}


/* Generic Padding Helpers
------------------------------------
*/

@mixin generate-paddings-options($n, $j:0) {
    @if $j <=$n {
        @for $i from $j through $n {
            $step: $i*5;
            .p-t-#{$step} {
                padding-top: ($step*1px) !important;
            }
            .p-r-#{$step} {
                padding-right: ($step*1px) !important;
            }
            .p-l-#{$step} {
                padding-left: ($step*1px) !important;
            }
            .p-b-#{$step} {
                padding-bottom: ($step*1px) !important;
            }
            .padding-#{$step} {
                padding: ($step*1px) !important;
            }
        }
    }
}

@include generate-paddings-options(10);

/* Generic Margin Helpers
------------------------------------
 */

@mixin generate-margin-options($n, $j: 0) {
    @if $j <=$n {
        @for $i from $j through $n {
            $step: $i*5;
            .m-t-#{$step} {
                margin-top: ($step*1px);
            }
            .m-r-#{$step} {
                margin-right: ($step*1px);
            }
            .m-l-#{$step} {
                margin-left: ($step*1px);
            }
            .m-b-#{$step} {
                margin-bottom: ($step*1px);
            }
        }
    }
}

@include generate-margin-options(10);
.full-height {
    height: 100% !important;
}

.full-width {
    width: 100%;
}

.hide {
    display: none !important;
}

.inline {
    display: inline-block !important;
}

.block {
    display: block !important;
}

.b-blank {
    border-color: #000;
}


/* Border Helpers
------------------------------------
*/

.b-a,
.b-r,
.b-l,
.b-t,
.b-b {
    border-style: solid;
    border-width: 0;
}

.b-r {
    border-right-width: 1px;
}

.b-l {
    border-left-width: 1px;
}

.b-t {
    border-top-width: 1px;
}

.b-b {
    border-bottom-width: 1px;
}

.b-a {
    border-width: 1px;
}

.b-dashed {
    border-style: dashed;
}

.b-thick {
    border-width: 2px;
}

.b-transparent {
    border-color: rgba(0, 0, 0, 0.4);
}

.b-transparent-white {
    border-color: rgba(255, 255, 255, 0.3);
}

.b-grey {
    border-color: $color-master-light;
}

.b-white {
    border-color: #fff;
}

.b-primary {
    border-color: $color-primary;
}

.b-complete {
    border-color: $color-primary;
}

.b-success {
    border-color: $color-success;
}

.b-info {
    border-color: $color-info;
}

.b-danger {
    border-color: $color-danger;
}

.b-warning {
    border-color: $color-warning;
}


/* Border Radius
------------------------------------
*/

.b-rad-sm {
    @include border-radius(3px);
}

.b-rad-md {
    @include border-radius(5px);
}

.b-rad-lg {
    @include border-radius(7px);
}

.no-border {
    border: none !important;
}


/** Profile Wrappers **/

.profile-img-wrapper {
    display: inline-block;
    float: left;
    border-radius: 100px 100px 100px 100px;
    display: inline-block;
    height: 35px;
    overflow: hidden;
    width: 35px;
    -webkit-flex: 1;
    -moz-flex: 1;
    flex: 1;
}

.profile-img-wrapper.big {
    height: 68px;
    width: 68px;
}

.profile-img-wrapper.with-left-space-custom {
    margin-left: 7px;
}

.relative {
    position: relative;
}

.flex-1 {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

[data-pages-bg-image] {
    background-size: cover;
}


/* Demo Purposes */

.icon-set-preview {
    transition: opacity 0.1s linear;
}

#icon-list {
    transition: all 0.1s ease-in-out;
}

.error-number {
    font-family: 'Open Sans';
    font-size: 90px;
    line-height: 90px;
}

.error-container-innner {
    margin-left: auto;
    margin-right: auto;
    width: 360px;
}

.error-container {
    margin-top: -100px;
    margin-left: auto;
    margin-right: auto;
    width: 38%;
}

.visible-xlg {
    display: none;
}

.hidden-xlg {
    display: block;
}

// Dashboard widget heights
.sm-gutter .row>[class^="col-"],
.sm-gutter .row>[class*="col-"] {
    padding-left: $layout-gutter-sm;
    padding-right: $layout-gutter-sm;
}

.sm-gutter .row {
    margin-left: -$layout-gutter-sm;
    margin-right: -$layout-gutter-sm;
}


/* Aspect ratio */

.ar-1-1,
.ar-2-1,
.ar-1-2,
.ar-3-2,
.ar-2-3 {
    .card {
        margin: 0;
    }
}

.ar-1-1 {
    position: relative;
    width: 100%;
    /* desired width */
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
        /* initial ratio of 1:1*/
    }
    &>div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

.ar-2-1 {
    position: relative;
    width: 100%;
    /* desired width */
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        padding-top: calc(50% - #{$layout-gutter-sm});
        /* initial ratio of 1:1*/
    }
    &>div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

.ar-1-2 {
    position: relative;
    width: 100%;
    /* desired width */
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        padding-top: calc(150% - #{$layout-gutter-sm});
        /* initial ratio of 1:1*/
    }
    &>div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

.ar-3-2 {
    position: relative;
    width: 100%;
    /* desired width */
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        padding-top: calc(75% - #{$layout-gutter-sm});
        /* initial ratio of 1:1*/
    }
    &>div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

.ar-2-3 {
    position: relative;
    width: 100%;
    /* desired width */
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        padding-top: calc(125% - #{$layout-gutter-sm});
        /* initial ratio of 1:1*/
    }
    &>div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

.v-align-bottom {
    vertical-align: bottom !important;
}

.v-align-top {
    vertical-align: top !important;
}

.v-align-middle {
    vertical-align: middle !important;
}


/* vertical alignment styles */

.col-top {
    vertical-align: top !important;
}

.col-middle {
    vertical-align: middle !important;
}

.col-bottom {
    vertical-align: bottom !important;
}


/* columns of same height styles
------------------------------------
*/

.container-xs-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.row-xs-height {
    display: table-row;
}

.col-xs-height {
    display: table-cell;
    float: none;
}

@media (min-width: 768px) {
    .container-sm-height {
        display: table;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
    }
    .row-sm-height {
        // display:table-row;
    }
    .col-sm-height {
        // display:table-cell !important;
        // float:none !important;
    }
}

@media (min-width: 992px) {
    .container-md-height {
        display: table;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
    }
    .row-md-height {
        display: table-row;
    }
    .col-md-height {
        display: table-cell !important;
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .container-lg-height {
        display: table;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
    }
    .row-lg-height {
        display: table-row;
    }
    .col-lg-height {
        display: table-cell !important;
        float: none !important;
    }
}