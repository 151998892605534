body.pgg-loaded {
    background-color: #e9ebec;
}

body.pgg-loaded>.content-container {
    display: inherit;
}

.autoHeight {
    min-height: 0 !important;
}

.default {
    cursor: default !important;
}

.full-height-vh {
    height: 100vh !important;
}

.b-t-light-grey {
    border-color: #bfc0c5 !important;
}

.b-light-grey {
    border-color: #bfc0c5 !important;
}

.b-lighter-grey {
    border-color: #ebebec !important;
}

.pointer {
    cursor: pointer;
}

.no-radius {
    border-radius: 0 !important;
}

.no-display {
    display: none;
}

.unlisted-list {
    list-style: none !important;
}

.radioTemplate label:before {
    top: 50%;
    margin-top: -8px;
}

.page-sidebar .sidebar-menu .menu-items>li:last-child {
    margin-bottom: 20px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-50 {
    font-size: 50px !important;
}

@media (max-width: 991px) {
    .m-b-sm-30 {
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    .m-b-xs-30 {
        margin-bottom: 30px;
    }
}

.flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-align-middle {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-valign-top {
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
}

.flex-valign-middle {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-halign-middle {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flex-row {
    flex-direction: row !important;
}

/* OVERWRITE NO TEMA */

.hide {
    display: none !important;
}

.btn-default-focus {
    box-shadow: none !important;
    outline: none !important;
}

.ui-select-container .ui-select-search {
    width: 100% !important;
}

.ui-select-container span.form-control {
    height: auto !important;
    padding: 7px 12px 3px !important;
}

.ui-select-container input.form-control {
    height: auto !important;
    padding: 7px 12px !important;
}

.ui-select-multiple.ui-select-bootstrap {
    padding: 7px 12px !important;
}

.input-group.input-daterange {
    width: 100%;
}

.header {
    background: #491058 !important;
    border: none !important;
    z-index: 900 !important;
}

.header .brand {
    width: auto !important;
    padding-left: 10px !important;
    padding-right: 20px !important;
    vertical-align: top !important;
}

.header .brand img {
    vertical-align: top !important;
    width: 190px !important;
}

@media (max-width: 768px) {
    .header .brand img {
        width: 170px;
    }
}

.header .thumb-bg {
    width: 90px !important;
    height: 90px !important;
    position: relative !important;
    top: 15px !important;
    float: none !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    padding: 5px !important;
}

.header-secondary {
    background-color: #123055 !important;
    height: 100px !important;
    z-index: 800 !important;
}

.secondary-sidebar {
    position: relative !important;
}

.page-sidebar {
    width: 340px !important;
    left: -270px !important;
    background-color: #2b303b !important;
    z-index: 1100 !important;
    will-change: left, right;
}

.page-sidebar .sidebar-header {
    background-color: #21252d !important;
    border-bottom: 1px solid #181b21 !important;
}

.sidebar-header .brand {
    width: 180px !important;
}

@media (max-width: 768px) {
    .sidebar-header .brand {
        width: 170px !important;
    }
}

.page-sidebar .sidebar-menu .menu-items>li ul.sub-menu {
    background-color: #21252d;
}

.icon-thumbnail {
    background-color: #21252d;
}

.icon-thumbnail .fa.bigger {
    font-size: 18px;
    line-height: 40px;
}

.bg-success {
    background-color: #2eaeb3;
}

.page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li .icon-thumbnail {
    background-color: #2b303b;
}

.page-sidebar .sidebar-header .sidebar-header-controls {
    -webkit-transform: translate3d(-40px, 0, 0);
    transform: translate3d(-40px, 0, 0);
    -ms-transform: translate(-40px, 0);
    position: relative;
    z-index: 100;
}

.page-sidebar .sidebar-header .marca-agua {
    background: url(/assets/img/marcadagua.png) no-repeat;
    width: 35px;
    height: 40px;
    position: absolute;
    -webkit-transition: -webkit-transform .4s cubic-bezier(.05, .74, .27, .99);
    transition: transform .4s cubic-bezier(.05, .74, .27, .99);
    -webkit-backface-visibility: hidden;
    opacity: 1;
    right: 17px;
    top: 13px;
}

body.sidebar-visible .page-sidebar .sidebar-header .sidebar-header-controls {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    -ms-transform: translate(10px, 0);
}

body.sidebar-visible .page-sidebar .sidebar-header .marca-agua {
    opacity: 0;
}

.page-sidebar .sidebar-menu .menu-items li.active>.icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items li.active>a,
.page-sidebar .sidebar-menu .menu-items li.open>.icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items li.open>a,
.page-sidebar .sidebar-menu .menu-items li:hover>.icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items li:hover>a,
.page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li:hover>.icon-thumbnail,
body.mobile .page-sidebar .sidebar-menu .menu-items li:hover .icon-thumbnail {
    color: #fff !important;
}

.page-sidebar .sidebar-menu .menu-items li.active>.icon-thumbnail.text-black,
.page-sidebar .sidebar-menu .menu-items li.open>.icon-thumbnail.text-black,
.page-sidebar .sidebar-menu .menu-items li:hover>.icon-thumbnail.text-black,
.page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li:hover>.icon-thumbnail.text-black,
body.mobile .page-sidebar .sidebar-menu .menu-items li:hover .icon-thumbnail.text-black {
    color: #000 !important;
}

.page-sidebar .sidebar-menu .menu-items li.active>.icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items li.active>a,
.page-sidebar .sidebar-menu .menu-items li.open>.icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items li.open>a,
.page-sidebar .sidebar-menu .menu-items li:hover>.icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items li:hover>a,
.page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li:hover>.icon-thumbnail {}

.page-sidebar .sidebar-menu .menu-items li.active>.icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items li.open>.icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items li:hover>.icon-thumbnail,
.page-sidebar .sidebar-menu .menu-items>li ul.sub-menu>li:hover>.icon-thumbnail {
    background-color: #0090b5 !important;
    color: #2b303b;
}

.page-sidebar .sidebar-menu .menu-items li>a {
    width: 70%;
}

@media only screen and (max-width: 991px) {
    .page-sidebar .sidebar-header {
        padding: 0 20px 0 20px;
    }
    .page-sidebar .sidebar-header .marca-agua {
        opacity: 0;
    }
}

@media (max-width: 991px) {
    .page-sidebar {
        width: 250px !important;
        left: 0 !important;
        -webkit-transform: translate3d(0, 0, 0) !important;
        transform: translate3d(0, 0, 0) !important;
    }
}

@media only screen and (min-width: 980px) {
    body.menu-pin .page-sidebar {
        transform: translate(270px, 0) !important;
        -webkit-transform: translate(270px, 0) !important;
        -ms-transform: translate(270px, 0) !important;
        width: 340px;
    }
    body.menu-pin .header .brand {
        width: 375px !important;
    }
    body.menu-pin .header div.pull-left {
        margin-left: 315px;
    }
    body.menu-pin .page-container .page-content-wrapper .content {
        padding-left: 300px !important;
    }
}

@media only screen and (min-width: 980px) {
    body.menu-pin .page-container .page-content-wrapper .content {
        padding-left: 300px;
    }
}

.dados-header {
    float: left !important;
    padding-left: 0 !important;
}

.dados-header>.thumbnail-wrapper {
    width: 90px;
    position: relative;
    top: -45px;
}

.dados-header .thumb-bg {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
}

.dados-header .text-holder {
    float: left;
    width: 250px;
}

.dados-header .text-holder ul {
    list-style: none;
    padding: 10px 0 0 0;
}

.dados-header .text-holder ul li {
    margin-bottom: 5px;
}

.dados-header .text-holder ul li:last-child {
    margin: 0 !important;
}

.header-secondary.yazigi .text-holder div {
    color: #b3d453 !important;
}

@media (max-width: 991px) {
    .dados-header {
        padding: 0 !important;
        margin-top: 10px;
    }
    .dados-header>.thumbnail-wrapper {
        width: 70px !important;
        height: 70px !important;
        top: 0 !important;
        float: left !important;
        margin-right: 15px !important;
    }
    .header a {
        color: #fff !important;
    }
}

@media (max-width: 768px) {
    .dados-header {
        width: 100%;
    }
    .dados-header .text-holder {
        width: 100%;
        margin-top: 10px;
        width: calc(100% - 100px);
    }
    .header-secondary {
        height: auto !important;
        padding-bottom: 10px;
    }
    .header-secondary .container-fluid {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media (max-width: 450px) {
    .header-secondary {
        text-align: center !important;
    }
    .dados-header,
    .dados-header .text-holder {
        width: 100%;
    }
    .dados-header>.thumbnail-wrapper {
        margin-left: 50% !important;
        left: -35px !important;
    }
    .dados-header .text-holder>ul {
        padding: 0 !important;
    }
}

.btn-sair-app {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
}

.btn-sair-app .pg {
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 0px 1px 1px 2px;
}

@media (max-width: 768px) {
    .btn-sair-app {
        margin-top: 3px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .btn-sair-app {
        margin-top: 10px;
    }
}

.page-container .page-content-wrapper .content {
    padding-top: 0;
}

.page-container.page-full {
    padding: 0 !important;
}

.jumbotron {
    margin-bottom: 0;
}

.content div[ui-view]>.jumbotron {
    background-color: #e9ebec !important;
}

.p-10 {
    padding: 15px 0;
    text-align: center;
}

.p-l-5 {
    padding-left: 5px;
}

.display-inline {
    display: inline;
}

.display-block {
    display: inline;
}

thead tr th[st-sort] {
    cursor: pointer !important;
}

.st-sort-ascent:after {
    content: '\f15d';
    margin: 0 0 0 15px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
}

.st-sort-descent:after {
    content: '\f15e';
    margin: 0 0 0 15px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
}

thead tr th[class*='st-sort'] {
    color: #2c2c2c;
}

textarea {
    resize: none;
}

.textAreaComentario {
    height: 200px !important;
}

/* PAGINAÇÃO */

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background-color: #2b303b;
    border-color: #2b303b;
}

.pagination>li>a,
.pagination>li>span {
    color: #2b303b;
}

/* ALERT */

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 2px;
}

.alert h4 {
    margin-top: 0;
    color: inherit;
}

.alert .alert-link {
    font-weight: bold;
}

.alert>p,
.alert>ul {
    margin-bottom: 0;
}

.alert>p+p {
    margin-top: 5px;
}

.alert-dismissable,
.alert-dismissible {
    padding-right: 35px;
}

.alert-dismissable .close,
.alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
}

.alert-success {
    color: #7cb342;
    background-color: #dcedc8;
    border-color: #9ccc65;
}

.alert-success hr {
    border-top-color: #90c652;
}

.alert-success .alert-link {
    color: #628e34;
}

.alert-success,
.alert-success h1,
.alert-success h2,
.alert-success h3,
.alert-success h4,
.alert-success h5,
.alert-success h6,
.alert-success small {
    color: #7cb342;
}

.alert-success hr {
    border-top-color: #90c652;
}

.alert-success .alert-link {
    color: #628e34;
}

.alert-info {
    color: #00acc1;
    background-color: #b2ebf2;
    border-color: #26c6da;
}

.alert-info hr {
    border-top-color: #22b3c5;
}

.alert-info .alert-link {
    color: #007f8e;
}

.alert-info,
.alert-info h1,
.alert-info h2,
.alert-info h3,
.alert-info h4,
.alert-info h5,
.alert-info h6,
.alert-info small {
    color: #00acc1;
}

.alert-info hr {
    border-top-color: #22b3c5;
}

.alert-info .alert-link {
    color: #007f8e;
}

.alert-warning {
    color: #ffb300;
    background-color: #ffecb3;
    border-color: #ffca28;
}

.alert-warning hr {
    border-top-color: #ffc40e;
}

.alert-warning .alert-link {
    color: #cc8f00;
}

.alert-warning,
.alert-warning h1,
.alert-warning h2,
.alert-warning h3,
.alert-warning h4,
.alert-warning h5,
.alert-warning h6,
.alert-warning small {
    color: #ffb300;
}

.alert-warning hr {
    border-top-color: #ffc40e;
}

.alert-warning .alert-link {
    color: #cc8f00;
}

.alert-danger {
    color: #dd191d;
    background-color: #f9bdbb;
    border-color: #e84e40;
}

.alert-danger hr {
    border-top-color: #e53929;
}

.alert-danger .alert-link {
    color: #af1417;
}

.alert-danger,
.alert-danger h1,
.alert-danger h2,
.alert-danger h3,
.alert-danger h4,
.alert-danger h5,
.alert-danger h6,
.alert-danger small {
    color: #dd191d;
}

.alert-danger hr {
    border-top-color: #e53929;
}

.alert-danger .alert-link {
    color: #af1417;
}

/* PNOTIFY */

.ui-pnotify {
    color: #fff;
}

.alert.ui-pnotify-container {
    padding: 24px;
    margin-bottom: 32px;
    border: none;
    border-radius: 0;
}

.ui-pnotify .alert-warning {
    background-color: #ffc107;
    border-color: #ffc107;
}

.ui-pnotify .alert-success {
    background-color: #8bc34a;
    border-color: #8bc34a;
}

.ui-pnotify .alert-danger {
    background-color: #e51c23;
    border-color: #e51c23;
}

.ui-pnotify .alert-info {
    background-color: #00bcd4;
    border-color: #00bcd4;
}

.ui-pnotify .alert-inverse {
    background-color: #757575;
    border-color: #757575;
}

.ui-pnotify .alert-primary {
    background-color: #03a9f4;
    border-color: #03a9f4;
}

.ui-pnotify hr {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.ui-pnotify .alert-link {
    color: #e6e6e6;
}

.ui-pnotify h1,
.ui-pnotify h2,
.ui-pnotify h3,
.ui-pnotify h4,
.ui-pnotify h5,
.ui-pnotify h6,
.ui-pnotify .h1,
.ui-pnotify .h2,
.ui-pnotify .h3,
.ui-pnotify .h4,
.ui-pnotify .h5,
.ui-pnotify .h6 {
    color: #fff;
}

.ui-pnotify h1 small,
.ui-pnotify h2 small,
.ui-pnotify h3 small,
.ui-pnotify h4 small,
.ui-pnotify h5 small,
.ui-pnotify h6 small,
.ui-pnotify .h1 small,
.ui-pnotify .h2 small,
.ui-pnotify .h3 small,
.ui-pnotify .h4 small,
.ui-pnotify .h5 small,
.ui-pnotify .h6 small {
    color: #fff;
}

.ui-pnotify .ui-pnotify-shadow {
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.24) !important;
}

.ui-pnotify-closer,
.ui-pnotify-sticker {
    padding: 2px 8px;
    margin: -14px 0 0 0 !important;
    background: rgba(0, 0, 0, 0.1);
    font-size: 10px;
    color: rgba(255, 255, 255, 0.4);
}

.ui-pnotify-closer:hover,
.ui-pnotify-sticker:hover,
.ui-pnotify-closer:active,
.ui-pnotify-sticker:active,
.ui-pnotify-closer:focus,
.ui-pnotify-sticker:focus {
    background: rgba(0, 0, 0, 0.2);
}

.ui-pnotify-closer {
    border-bottom-right-radius: 2px;
}

.ui-pnotify-sticker {
    border-bottom-left-radius: 2px;
}

.ui-pnotify-text {
    font-size: 12px !important;
    color: #fff;
    font-weight: lighter;
}

.ui-pnotify-title {
    font-size: 14px !important;
    color: #fff;
    line-height: 15px !important;
}

.ui-pnotify-icon,
.ui-pnotify-icon span {
    margin-top: 0.05em !important;
    color: #fff;
}

.panel-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: rgba(255, 255, 255, 0.75);
}

.panel-loader-circular-img {
    width: 90px;
    height: 90px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -45px;
    margin-left: -45px;
}

.panel-loader-circular-img img {
    width: 90px;
    height: 90px;
}

.panel-loading.small .panel-loader-circular-img {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -20px;
    margin-left: -20px;
}

.panel-loading.small .panel-loader-circular-img img {
    width: 40px;
    height: 40px;
}

.panel-loader-circular {
    -webkit-animation: animateloader 1.5s linear infinite;
    -o-animation: animateloader 1.5s linear infinite;
    animation: animateloader 1.5s linear infinite;
    clip: rect(0, 80px, 80px, 40px);
    width: 90px;
    height: 90px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -45px;
    margin-left: -45px;
}

.panel-loader-circular:after {
    -webkit-animation: animateloader2 1.5s ease-in-out infinite;
    -o-animation: animateloader2 1.5s ease-in-out infinite;
    animation: animateloader2 1.5s ease-in-out infinite;
    clip: rect(0, 80px, 80px, 40px);
    content: '';
    border-radius: 50%;
    height: 80px;
    width: 80px;
    position: absolute;
}

@-webkit-keyframes animateloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(220deg);
    }
}

@keyframes animateloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(220deg);
    }
}

@keyframes animateloader2 {
    0% {
        box-shadow: inset #03a9f4 0 0 0 17px;
        transform: rotate(-140deg);
    }
    50% {
        box-shadow: inset #03a9f4 0 0 0 2px;
    }
    100% {
        box-shadow: inset #03a9f4 0 0 0 17px;
        transform: rotate(140deg);
    }
}

@-webkit-keyframes animateloader2 {
    0% {
        box-shadow: inset #03a9f4 0 0 0 17px;
        transform: rotate(-140deg);
    }
    50% {
        box-shadow: inset #03a9f4 0 0 0 2px;
    }
    100% {
        box-shadow: inset #03a9f4 0 0 0 17px;
        transform: rotate(140deg);
    }
}

.panel-loader-dots {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -6px;
    border-radius: 12px;
    -webkit-animation: loader10m 3s ease-in-out infinite;
    -o-animation: loader10m 3s ease-in-out infinite;
    animation: loader10m 3s ease-in-out infinite;
}

.panel-loader-dots:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -25px;
    height: 12px;
    width: 12px;
    border-radius: 12px;
    -webkit-animation: loader10g 3s ease-in-out infinite;
    -o-animation: loader10g 3s ease-in-out infinite;
    animation: loader10g 3s ease-in-out infinite;
}

.panel-loader-dots:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 25px;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    -webkit-animation: loader10d 3s ease-in-out infinite;
    -o-animation: loader10d 3s ease-in-out infinite;
    animation: loader10d 3s ease-in-out infinite;
}

@-webkit-keyframes loader10g {
    0% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    25% {
        background-color: #03a9f4;
    }
    50% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    75% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    100% {
        background-color: rgba(3, 169, 244, 0.2);
    }
}

@keyframes loader10g {
    0% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    25% {
        background-color: #03a9f4;
    }
    50% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    75% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    100% {
        background-color: rgba(3, 169, 244, 0.2);
    }
}

@-webkit-keyframes loader10m {
    0% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    25% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    50% {
        background-color: #03a9f4;
    }
    75% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    100% {
        background-color: rgba(3, 169, 244, 0.2);
    }
}

@keyframes loader10m {
    0% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    25% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    50% {
        background-color: #03a9f4;
    }
    75% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    100% {
        background-color: rgba(3, 169, 244, 0.2);
    }
}

@-webkit-keyframes loader10d {
    0% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    25% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    50% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    75% {
        background-color: #03a9f4;
    }
    100% {
        background-color: rgba(3, 169, 244, 0.2);
    }
}

@keyframes loader10d {
    0% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    25% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    50% {
        background-color: rgba(3, 169, 244, 0.2);
    }
    75% {
        background-color: #03a9f4;
    }
    100% {
        background-color: rgba(3, 169, 244, 0.2);
    }
}

.panel-loader-clock {
    border-radius: 60px;
    border: 3px solid #03a9f4;
    height: 80px;
    width: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -43px;
    margin-left: -43px;
}

.panel-loader-clock:after {
    content: "";
    position: absolute;
    background-color: #03a9f4;
    top: 2px;
    left: 48%;
    height: 38px;
    width: 4px;
    border-radius: 5px;
    -webkit-transform-origin: 50% 97%;
    -moz-transform-origin: 50% 97%;
    -ms-transform-origin: 50% 97%;
    transform-origin: 50% 97%;
    -webkit-animation: grdAiguille 2s linear infinite;
    -o-animation: grdAiguille 2s linear infinite;
    animation: grdAiguille 2s linear infinite;
}

.panel-loader-clock:before {
    content: "";
    position: absolute;
    background-color: #03a9f4;
    top: 6px;
    left: 48%;
    height: 35px;
    width: 4px;
    border-radius: 5px;
    -webkit-transform-origin: 50% 94%;
    -moz-transform-origin: 50% 94%;
    -ms-transform-origin: 50% 94%;
    transform-origin: 50% 94%;
    -webkit-animation: ptAiguille 12s linear infinite;
    -o-animation: ptAiguille 12s linear infinite;
    animation: ptAiguille 12s linear infinite;
}

@-webkit-keyframes grdAiguille {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes grdAiguille {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes ptAiguille {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes ptAiguille {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.image-thumb {
    width: auto;
    max-width: 600px;
}

text-angular .btn-group button {
    border: 1px solid #d7d7d7;
    border-radius: 0;
}

text-angular {
    min-height: 400px;
}

.image-thumb-galeria {
    max-width: 150px;
    display: inline-block;
    margin: 0 auto;
}

.table-galeria tr td {
    padding: 8px;
}

.table tr td img {
    width: 80px;
}

.materialpicker-colors {
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    border: 1px solid #272b35;
    border-radius: 2px;
    display: inline-block;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    cursor: default;
}

.materialpicker-colors div.selected {
    position: relative;
    z-index: 2;
    box-shadow: 0 0 4px #000;
    border: 2px solid #FFF;
}

div.materialpicker-white.selected {
    border-color: #78909C;
}

.materialpicker.ng-invalid .materialpicker-colors {
    border-color: #a94442;
    box-shadow: 0 1px 5px #a94442;
}

.identifierColorCategoria {
    width: 15px;
    height: 15px;
    border-radius: 30px;
    display: inline-block;
}

/*.table-galeria-upload .progress {
    width: 150px;
}*/

.rowBtnsEditFolder {
    min-height: 40px;
}

.fileView {
    -webkit-animation: linear 4s linear infinite;
    -o-animation: linear 4s linear infinite;
    animation: linear 4s linear infinite;
}

.fileView .table.table-striped tbody tr.repeated td {
    background: #ffa4a4 !important;
    color: #000;
}

.fileView.loading {
    padding-top: 110px;
}

.iconeFiles {
    width: 40px !important;
    height: auto;
    margin: 0 auto;
}

.iconeFiles.bigger {
    width: 100px;
}

.form-group label .help {
    margin-left: 0;
    font-weight: lighter;
    text-transform: initial;
    font-family: "Segoe UI", Arial, sans-serif;
    display: block;
    line-height: 11px;
    margin-bottom: 7px;
}

/* Daterangepicker
http://www.dangrossman.info/
*/

.daterangepicker {
    background: #fff !important;
}

.daterangepicker .calendar .calendar-date {
    border: 1px solid rgba(0, 0, 0, 0.07) !important;
}

.daterangepicker .calendar .prev,
.daterangepicker .calendar .next,
.daterangepicker .calendar th {
    color: #5d598d !important;
    text-transform: uppercase !important;
    font-size: 11px !important;
}

.daterangepicker .calendar .month {
    color: #5d598d !important;
    font-size: 13px !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #5d598d !important;
    border-color: #5d598d !important;
}

#iframeConstrutor {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.ace_editor {
    height: 400px;
}

#modalPreview .btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.holder-html .form-control[disabled],
.holder-html .form-control[readonly],
.holder-html fieldset[disabled] .form-control {
    color: #495b62 !important;
}

/* DASHBOARD */

.widget-admin.widget-9 {
    height: auto !important;
}

.pending-comments-widget .widget-header {
    background: #3B4753;
}

.pending-comments-widget h5 {
    color: #C1C3C6;
    font-weight: 500;
    font-size: 13px;
}

.pending-comments-widget .nav-tabs li.active a {
    color: #FFF;
}

.pending-comments-widget .nav-tabs li:not(.active) a {
    color: #C1C3C6;
}

.pending-comments-widget .nav-tabs-simple::after {
    position: static;
    border: none;
}

.pending-comments-widget .scrollable {
    height: 400px;
    margin-bottom: 0 !important;
}

.pending-comments-widget .comment-button {
    color: #000 !important;
}

.pending-comments-widget .view-button::before {
    content: '|';
    margin-left: -7px;
    margin-right: 25px;
    color: #C1C3C6;
}

.pending-comments-widget .select2-choice {
    border: none;
    background-color: transparent;
    font-size: 18px;
    color: #FFF;
}

.pending-comments-widget .comment-wrapper {
    width: calc(100% - 45px);
}

.pending-comments-widget .comment-username {
    font-weight: 500;
}

#portletMaisVistos .table {
    margin: 0 !important;
}

#accComentarios .panel-heading a,
#accComentarios .panel-heading a::after {
    color: #ffffff !important;
}

#portletMaisVistos .table-responsive.scrollable {
    height: auto;
    max-height: 600px;
    position: relative;
    overflow: hidden;
}

@media(max-width: 991px) {
    .widget-admin.widget-9 {
        margin-bottom: 10px !important;
    }
    #portletQuantidade .panel-body,
    #portletMaisVistos .panel-body {
        padding-bottom: 10px !important;
    }
}

.modal-backdrop {
    z-index: 1200;
}

.modal {
    z-index: 1300;
    padding-right: 0 !important;
}

@media (max-width: 992px) {
    .modalCrop.modal-lg {
        width: 100%;
    }
    .modal.modalCrop .modal-dialog {
        margin: 0 !important;
    }
}

@media (max-width: 768px) {
    .modalCrop.modal .modal-body {
        overflow-x: scroll;
        padding: 0;
    }
}

/* RELATÓRIO GERAL */

.table-relatorio {
    height: 300px;
}