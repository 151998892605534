.login-logo {
    background: $purple;
}

.form-control {
    &[disabled],
    &[readonly],
    fieldset[disabled] & {
        background: #ffffff;
        color: $color-master-dark;
    }
}