/*------------------------------------------------------------------
[2. View Ports]
*/
.view-port{
	position: relative;
	width: 100%;
	height: 100%;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	.navbar{
		border-radius: 0;
		padding-left: 0;
		margin-bottom:0;
		border-left: 0;
		display: table;
		width: 100%;
		top: 0;
		border-top: 0;
		padding: 0;
		border-bottom: 1px solid #e7e7e7;
		.navbar-inner{
			display: table-cell;
			height: 50px;
			vertical-align: middle;
		}
		.action{
			position: absolute;
			top:0;
			line-height: 50px;
			z-index: 1;
			&.pull-right{
				right: 0;
			}
		}
		.view-heading{
			font-size: 15px;
			text-align: center;
		}
		& > p{
			line-height: 12px;
			font-size: 12px;
			margin: 0;
		}
		&.navbar-sm{
			min-height: 35px;
			.navbar-inner{
				height: 35px;
			}
			.action{
				line-height: 35px;
			}
		}
	}
	.view{
		display: inline-block;
		width: 100%;
		height: 100%;
		vertical-align: top;
		@include backface-visibility(hidden);
		-webkit-perspective: 1000;
		@include transition(all 0.4s ease);
		&:first-child{
			&:before{
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				background-color: #000;
				opacity:0;
				@include transition(opacity 0.2s linear);
				z-index: -1
			}
		}
		&:last-child{
			margin-left: -4px
		}
		&:only-child{
			margin: 0;
		}
	}
	&.from-top{
		& > .view{
			&:last-child{
				@include translate3d(-100%,0,0);
			}
		}
	}
	&.push{
		& > .view{
			&:first-child{
				@include translate3d(-100%,0,0);
			}
			&:last-child{
				@include translate3d(-100%,0,0);
			}
		}
	}
	&.push-parrallax{
		& > .view{
			&:first-child{
				@include transition(all 400ms cubic-bezier(0.1,.7,.1,1));
				@include translate3d(-25%,0,0);
				&:before{
					opacity:0;
					z-index: 100;
				}
			}
			&:last-child{
				@include transition(all 400ms cubic-bezier(0.1,.7,.1,1));
				@include translate3d(-100%,0,0);
				box-shadow:0 0 9px rgba(191,191,191,.36);
			}
		}
	}
}